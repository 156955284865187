@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/style/mixins';

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, p {
  margin: 0;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.libre {
  font-family: 'Libre Baskerville', serif;
}

.text-sm {
  font-size: 0.875rem;
}

.text-title {
  font-size: 3.5rem;
  line-height: 4.3rem;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.home-program-track {
  z-index: 20;
}

.fw-700 {
  font-weight: 700;
}

.home-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  transform-origin: right top;
  @include for-size(tablet-portrait-up) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    "top-left center"
    "bottom-left right";
    width: calc(100vw - 64px);
  }
  @include for-size(desktop-up) {
    grid-template-areas:
    "top-left center center right"
    "top-left center center right"
    "top-left center center right"
    "bottom-left bottom-left bottom-left right";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  @include for-size(phone-only) {
    display: flex;
    flex-direction: column;
    margin-top: 88px;
  }
}

.top-left {
  grid-area: top-left;
  background-color: #42BEB6
}

.center {
  grid-area: center;
  background-color: #0AAA4D
}

.right {
  grid-area: right;
  background-color: #e7a024;
}

.bottom-left {
  grid-area: bottom-left;
  background-color: #2E3192
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slide {
  width: calc(100vw - 64px);
  height: 100vh;
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: calc(400vw + 2535px + 1000px);
  transform: rotate(90deg) translateY(-100vh) translateX(64px);
  transform-origin: top left;
  @include for-size(phone-only) {
    position: relative;
    transform: none;
    overflow: auto;
    height: auto;
    width: auto;
    flex-direction: column;
  }
}

.outer-wrapper {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @include for-size(phone-only) {
    position: relative;
    transform: none;
    overflow: auto;
    height: auto;
    width: auto;
  }
}

::-webkit-scrollbar {
  display: none;
}

.one {
  background: #efdefe;
}

.two {
  width: 1133px;
  height: 100vh;
  @apply bg-calmYellow;
  .col-span-2 {
    padding: 140px 120px 72px 72px;
    @include for-size(phone-only) {
      padding: 32px 24px;
    }
  }

  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.three {
  height: 100vh;
  @apply bg-white text-deepSea;
  .col-span-2 {
    padding: 140px 120px 72px 72px;
    @include for-size(phone-only) {
      padding: 32px 24px;
    }
  }

  @include for-size(tablet-portrait-up) {
    width: calc(100% - 150px);
  }
  @include for-size(tablet-landscape-up) {
    width: 1692px;
  }
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.four {
  width: 1596px;
  height: 100vh;
  @apply bg-calmYellow text-deepSea;
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.eight {
  height: 100vh;
  overflow: hidden;
  @apply bg-azure text-deepSea;
  .col-span-2 {
    padding: 140px 120px 72px 72px;
    @include for-size(phone-only) {
      padding: 32px 24px;
    }
  }

  @include for-size(tablet-portrait-up) {
    width: calc(100% - 150px);
  }
  @include for-size(tablet-landscape-up) {
    width: 100vw;
  }
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.seven {
  height: 100vh;
  padding: 64px 72px;
  @apply bg-white text-deepSea;
  @include for-size(tablet-portrait-up) {
    width: calc(100% - 150px);
  }
  @include for-size(tablet-landscape-up) {
    width: 1192px;
  }
  @include for-size(desktop-up) {
    width: 1440px;
  }
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
    padding: 32px 24px;
  }
}

.five {
  width: 560px;
  height: 100vh;
  @apply bg-azure text-deepSea;
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.ten {
  width: 1100px;
  height: 100vh;
  flex-direction: row;
  @apply bg-madViolet text-deepSea;
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
}

.six {
  width: 560px;
  height: 100vh;
  @apply bg-azure text-deepSea;
}

.nine {
  width: 3176px;
  height: 100vh;
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
  @include for-size(tablet-portrait-up) {
    img {
      max-width: unset;
      height: unset;
    }
  }
  @include for-size(tablet-landscape-up) {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.logo {
  left: 24px;
  top: 24px;
  z-index: 1;
}

.program {
  position: fixed;
  right: 24px;
  top: 24px;
  z-index: 1;
}

.animate {
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section__title {
  -webkit-text-stroke: .5px white;
  font-size: 60px;
  line-height: 80px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  word-spacing: 20px;
  transform: rotate(180deg);
  font-family: 'Libre Baskerville', serif;
}

.mt-121 {
  margin-top: 75px;
}

.youtube-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  z-index: 0;
}

.share-icons {
  @include for-size(desktop-up) {
    right: 18%;
    top: 120px;
  }
}

.text-upside-down {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  @include for-size(phone-only) {
    writing-mode: unset;
    text-orientation: unset;
  }
}

.footer {
  height: 100vh;
  @include for-size(phone-only) {
    height: auto;
  }
}

.home-program-track {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
}

.instagram-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 28px;
  grid-auto-rows: min-content;
  padding: 141px 32px 32px 32px;
  @include for-size(phone-only) {
    grid-gap: 4px;
    margin: 32px auto;
    padding: 0;
  }
}

.made-by {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}

.filter-post--zindex {
  z-index: 31 !important;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}


// NOVO

/* Webfont: Thunder-BoldHC */
@font-face {
  font-family: 'Thunder';
  src: url('./assets/fonts/Thunder-BoldLC.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/Thunder-BoldLC.woff2') format('woff2'), /* Modern Browsers */
  url('./assets/fonts/Thunder-BoldLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

.thunder {
  font-family: "Thunder", sans-serif;
}

.home-intro {
  @apply bg-deepSea;
  @include for-size(tablet-portrait-up) {
    width: 100vw;
    height: 90vw;
  }
  @include for-size(tablet-landscape-up) {
    width: 100vh;
    height: calc(100vh - 88px);
  }
  @include for-size(phone-only) {
    width: 100vw;
    height: 100vw;
  }

  h1 {
    @include for-size(tablet-portrait-up) {
      font-family: 'Thunder';
      font-style: normal;
      font-weight: 700;
      font-size: 100px;
      line-height: 98px;
      text-align: right;
      text-transform: uppercase;
      color: #e7a024;
      padding-right: 56px;
    }
    @include for-size(tablet-landscape-up) {
      font-family: 'Thunder';
      font-style: normal;
      font-weight: 700;
      font-size: 18vh;
      line-height: 18vh;
      text-align: right;
      text-transform: uppercase;
      color: #e7a024;
      padding-right: 12vh;
    }
    @include for-size(phone-only) {
      padding-right: 40px;
      font-family: 'Thunder';
      font-style: normal;
      font-weight: 700;
      font-size: 63px;
      line-height: 98px;
      text-align: right;
      text-transform: uppercase;
      color: #e7a024;
    }
  }

  .date {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 5vh;
    text-align: right;
    text-transform: uppercase;
    color: #e7a024;
    @include for-size(tablet-portrait-up) {
      padding-right: 56px;
      line-height: 100%;
    }
    @include for-size(tablet-landscape-up) {
      padding-right: 12vh;
      line-height: 0;
    }
    @include for-size(phone-only) {
      padding-right: 40px;
    }
  }
}

.mobilePadding {
  @include for-size(phone-only) {
    padding-top: 48px;
  }
}

.home-end {
  @apply bg-madViolet;

  @include for-size(tablet-portrait-up) {
    width: 100%;
  }
  @include for-size(tablet-landscape-up) {
    width: 1059px;
    height: 100vh;
  }
  @include for-size(phone-only) {
    width: 100%;
  }
}

.number {
  @include for-size(tablet-portrait-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 300px;
    line-height: 276px;
    text-transform: uppercase;
    color: #e7a024;
    padding-right: 65px;
  }
  @include for-size(tablet-landscape-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 42vh;
    text-transform: uppercase;
    padding-right: 12vh;
    color: #e7a024;
  }
  @include for-size(phone-only) {
    width: 100vw;
    padding-right: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 200px;
    line-height: 184px;
    text-transform: uppercase;
    color: #e7a024;
  }
}

.parent {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 30%;
  grid-auto-rows: 35%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @include for-size(phone-only) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 59%;
    grid-auto-rows: 35%;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
  border-bottom: 2px solid white;
}

.div2 {
  grid-area: 2 / 1 / 3 / 2;
  border-bottom: 2px solid white;
  @include for-size(phone-only) {
    grid-area: auto;
  }
}

.div3 {
  grid-area: 3 / 1 / 4 / 2;
  @include for-size(phone-only) {
    grid-area: auto;
  }
}

.home-lecture {

  // background-image: url('./assets/images/predavanje-2023.png');
  background-size: cover;
  background-position: center center;
  @include for-size(tablet-portrait-up) {
    width: 100%;
    height: auto;
  }
  @include for-size(tablet-landscape-up ) {
    height: 100vh;
    width: 70vh;
  }
  @include for-size(phone-only) {
    width: 100%;
    height: auto;
  }
}

.home-sport {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include for-size(tablet-portrait-up){
    width: 100%;
    height: 538px;
    position: relative;
  }
  @include for-size(tablet-landscape-up){
    width: 66vh;
    height: 100vh;
    position: relative;
  }
  @include for-size(phone-only){
    width: 100%;
    height: 170vw;
    position: relative;
  }
}

.home-entertaiment {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include for-size(tablet-portrait-up){
    width: 100%;
    height: 538px;
    position: relative;
  }
  @include for-size(tablet-landscape-up){
    width: 66vh;
    height: 100vh;
    position: relative;
  }
  @include for-size(phone-only){
    width: 100%;
    height: 170vw;
    position: relative;
  }
}

.home-culture {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include for-size(tablet-portrait-up){
    width: 100%;
    height: 538px;
    position: relative;
  }
  @include for-size(tablet-landscape-up){
    width: 66vh;
    height: 100vh;
    position: relative;
  }
  @include for-size(phone-only){
    width: 100%;
    height: 170vw;
    position: relative;
  }
}

.home-education {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include for-size(tablet-portrait-up){
    width: 100%;
    height: 538px;
    position: relative;
  }
  @include for-size(tablet-landscape-up){
    width: 66vh;
    height: 100vh;
    position: relative;
  }
  @include for-size(phone-only){
    width: 100%;
    height: 170vw;
    position: relative;
  }
}

.events {
  background-color: #e7a023;
  @include for-size(phone-only) {
    background-color: #e7a023;
  }


  h3 {
    @include for-size(tablet-portrait-up){
      width: 294px;
    }
    @include for-size(tablet-landscape-up){
      width: 365px;
    }
  }
}

.social-networks {
  background-color: #e7a023;

  @include for-size(tablet-portrait-up) {
    padding: 35px 80px 0;
  }

  @include for-size(tablet-landscape-up) {
    padding: 35px 104px;
  }

  svg {
    width: 34vh;
  }

  img {
    width: 30vh;
    height: 30vh;
    display: block;
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
  }

  &__insta {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 120px;
    line-height: 117px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  a {
    margin-top: 12px;
    margin-left: 2vh;
  }

  @responsive {
    .margin-vh {
      margin-bottom: 5vh;
    }
  }
}

.rotated {
  @include for-size(tablet-portrait-up ) {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
  @include for-size(tablet-landscape-up) {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
  }
  @include for-size(phone-only) {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}

.sans {
  font-family: 'Public Sans', sans-serif;
}

.text-video {
  font-style: normal;
  font-weight: 500;
  font-size: 170px;
  line-height: 156px;
  text-transform: uppercase;
  position: absolute;

  &__left {
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    position: absolute;
    bottom: 40px;
    margin-left: 40px;
    margin-top: 0;
    line-height: 133px;
  }

  &__right {
    writing-mode: vertical-rl;
    position: absolute;
    top: 18%;
    right: 40px;
    margin-left: 40px;
    margin-top: 0;
  }

  &__bottom {
    position: absolute;
    margin-left: 40px;
    margin-top: 0;
    bottom: 40px;
    right: 40px;
    line-height: 113px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-container {
  width: 60rem;
  height: 60rem;
}

.post__image {
  max-width: 57vh;
  width: 100%;
  @include for-size(tablet-portrait-up) {
    width: 480px;
  }
  @include for-size(desktop-up) {
    width: 57vh;
  }
}

.post__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.video-container {
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: black;
}

.category__image {
  max-height: 62vh;
  display: inline-block;
  @include for-size(tablet-portrait-up ) {
    width: 294px
  }
  @include for-size(tablet-landscape-up) {
    width: auto;
  }
  @include for-size(phone-only) {
    width: 74%;
  }
}

.program__max-width {
  max-width: 1114px;
}

table.customTable {
  width: 100%;
  background-color: #FFFFFF;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #CDCDCD;
  border-style: solid;
  color: #CDCDCD;
}

table.customTable td, table.customTable th {
  border-width: 1px;
  border-color: #CDCDCD;
  border-style: solid;
  padding: 5px 16px;
}

table.customTable thead {
  background-color: #FFFFFF;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @apply text-deepSea;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #555555;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #555555;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.text-h1 {
  @include for-size(tablet-portrait-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 64px;
    text-transform: uppercase;
  }
  @include for-size(tablet-landscape-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
    line-height: 92px;
    text-transform: uppercase;
  }
}

.category__title {
  z-index: 10;
  position: absolute;
  text-transform: uppercase;
  @apply text-calmYellow;
  @include for-size(tablet-portrait-up) {
    top: 40px;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 46px;
    text-transform: uppercase;
    color: #e7a024;
    left: 20px;
    top: 20%;
  }
  @include for-size(tablet-landscape-up) {
    top: 40px;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 13vh;
    font-size: 11vh;
    left: 40px;
    top: 20%;
  }
  @include for-size(phone-only) {
    top: 40px;
    left: 40px;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 74px;
    top: 20%;
    text-transform: uppercase;
  }
}

.facebook-image {
  background-image: url('/assets/images/facebook.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 33vh;
  width: 33vh;
}

.paw-l {
  @include for-size(tablet-landscape-up) {
    margin: -5px -5px -3px 12px;
  }
  @include for-size(phone-only) {
    margin: -5px -5px -3px 4px;
  }
}

.paw-r {
  @include for-size(tablet-landscape-up) {
    margin: 4px 8px -4px -3px;
  }
  @include for-size(phone-only) {
    margin: 4px 4px -4px -3px;
  }
}

.filter_posts {
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100vh);
  padding-top: 108px;
  transition: ease-in-out 0.4s;
  transform: translateX(0);
  z-index: 10;
  @include for-size(phone-only) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100%);
  }
  &--program {
    @include for-size(tablet-landscape-up) {
      height: calc(100vh - 204px);
      padding-top: 300px;
      z-index: 31;
    }
    @include for-size(tablet-portrait-up) {
      height: calc(100vh - 204px);
      padding-top: 0;
    }
    @include for-size(desktop-up) {
      height: calc(100vh - 235px);
      padding-top: 0;
      z-index: 21;
    }
    bottom: 0;
    top: unset;
  }
}

@responsive {
  .pl-max {
    margin-left: 348px !important;
  }
  .pt-posts {
    padding-top: 108px;
  }
}

.title-posts {
  @include for-size(tablet-portrait-up) {
      position: relative;
      left: unset;
      right: unset;
      font-family: 'Thunder';
      font-style: normal;
      font-weight: 500;
      font-size: 70px;
      line-height: 64px;
      text-transform: uppercase;
      @apply text-calmRed;
  }
  @include for-size(tablet-landscape-up) {
    position: fixed;
    left: 348px;
    bottom: 5.86%;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 100px;
    line-height: 92px;
    text-transform: uppercase;
    @apply text-calmRed;
  }
  @include for-size(phone-only) {
    position: relative;
    left: unset;
    right: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 46px;
    text-transform: uppercase;
    @apply text-calmRed;
  }
}

.pagination {
  font-family: 'Thunder';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 32px;
  text-transform: uppercase;
  color: #15173E;
  @include for-size(tablet-portrait-up){
    position: relative;
    bottom: unset;
    right: unset;
  }
  @include for-size(tablet-landscape-up){
    position: fixed;
    right: 80px;
    bottom: 5.86%;
  }
  @include for-size(phone-only){
    position: relative;
    bottom: unset;
    right: unset;
  }
}

.page-number {
  @include for-size(tablet-portrait-up) {
    position: absolute;
    left: unset;
    right: 134px;
    top: 221px;
    bottom: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 180px;
    line-height: 166px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.2;
  }
  @include for-size(tablet-landscape-up) {
    position: fixed;
    bottom: 4.6%;
    right: unset;
    top: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 502;
    font-size: 180px;
    line-height: 166px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.2;
  }
  @include for-size(phone-only) {
    position: relative;
    left: unset;
    bottom: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 64px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.2;
  }
  &--one {
    @include for-size(tablet-portrait-up) {
      left: unset;
      right: 130px;
    }
    @include for-size(tablet-landscape-up) {
      left: 600px;
      right: unset;
    }
    @include for-size(phone-only) {
      left: unset;
      right: unset;
    }
    &.en {
      @include for-size(tablet-portrait-up) {
        left: unset;
        right: 80px;
      }
      @include for-size(tablet-landscape-up) {
        left: 545px;
        right: unset;
      }
      @include for-size(phone-only) {
        left: unset;
        right: unset;
      }
    }
  }
  &--other{
    @include for-size(tablet-portrait-up) {
      left: unset;
      right: 130px;
    }
    @include for-size(tablet-landscape-up) {
      left: 610px;
      right: unset;
    }
    @include for-size(phone-only) {
      left: unset;
      right: unset;
    }
    &.en {
      left: 555px;
      @include for-size(tablet-portrait-up) {
        left: unset;
        right: 80px;
      }
      @include for-size(tablet-landscape-up) {
        left: 555px;
        right: unset;
      }
      @include for-size(phone-only) {
        left: unset;
        right: unset;
      }
    }
  }
}

.page {
  @include for-size(tablet-portrait-up) {
    position: absolute;
    left: unset;
    right: 32px;
    top: 333px;
    bottom: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 32px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.1;
  }
  @include for-size(tablet-landscape-up) {
    position: fixed;
    bottom: 6.7%;
    top: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 32px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.1;
  }
  @include for-size(phone-only) {
    position: relative;
    left: unset;
    bottom: unset;
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-transform: uppercase;
    color: #15173E;
    opacity: 0.1;
  }
  &--one {
    @include for-size(tablet-portrait-up) {
      left: unset;
      right: 32px;
    }
    @include for-size(tablet-landscape-up) {
      left: 652px;
      right: unset;
    }
    @include for-size(phone-only) {
      left: unset;
      right: unset;
    }
    &.en {
      @include for-size(tablet-portrait-up) {
        left: unset;
        right: 32px;
      }
      @include for-size(tablet-landscape-up) {
        left: 600px;
        right: unset;
      }
      @include for-size(phone-only) {
        left: unset;
        right: unset;
      }
    }
  }
  &--other{
    left: 690px;
    @include for-size(tablet-portrait-up) {
      left: unset;
      right: 32px;
    }
    @include for-size(tablet-landscape-up) {
      left: 690px;
      right: unset;
    }
    @include for-size(phone-only) {
      left: unset;
      right: unset;
    }
    &.en {
      @include for-size(tablet-portrait-up) {
        left: unset;
        right: 32px;
      }
      @include for-size(tablet-landscape-up) {
        left: 640px;
        right: unset;
      }
      @include for-size(phone-only) {
        left: unset;
        right: unset;
      }
    }
  }
}

.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  row-gap: 24px;
  @include for-size(tablet-landscape-up) {
    margin-top: 120px;
  }
  @include for-size(tablet-portrait-up) {
    margin-top: 80px;
  }
  @include for-size(phone-only) {
    margin-top: 72px;
    gap: 12px;
    row-gap: 12px;
  }
  .wp-block-image {
    @include for-size(tablet-portrait-up) {
      width: 30%;
      height: 181px;
    }
    @include for-size(tablet-landscape-up) {
      width: 31%;
      height: 181px;
    }
    @include for-size(phone-only) {
      width: 48%;
      height: 155px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.post {
  .paw-l {
    margin: -5px -3px -3px 4px;
  }
  .paw-r {
    margin: 4px 4px -4px -3px;
  }
}

.related-image {
  width: 500px !important;
  height: 56%;
  object-fit: cover;
  object-position: center;
  @include for-size(tablet-portrait-up) {
    width: 30%;
    height: 260px;
  }
  @include for-size(tablet-landscape-up) {
    width: 31%;
    height: 219px;
  }
  @include for-size(desktop-up) {
    width: 31%;
    height: 260px;
  }
  @include for-size(phone-only) {
    width: 48%;
    height: 155px;
  }
}

.program-container {
  overflow: auto;
  @include for-size(tablet-portrait-up) {
    height: calc(100vh - 307px);
  }
  @include for-size(tablet-landscape-up) {
    height: calc(100vh - 277px);
  }
  @include for-size(desktop-up) {
    height: calc(100vh - 307px);
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vh;
  background: #555555;
  opacity: 0.2;
  overflow: hidden;
  z-index: 20;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-9 15:44:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.instagram {
  @include for-size(tablet-portrait-up) {
    margin: 0 auto 48px 0;
  }
  @include for-size(tablet-landscape-up) {
    margin: 0;
  }
  @include for-size(phone-only) {
    margin: 0 auto 48px 0;
  }
}

.facebook {
  @include for-size(tablet-portrait-up) {
    margin: 0 auto 48px 0;
  }
  @include for-size(tablet-landscape-up) {
    margin: 0;
  }
  @include for-size(phone-only) {
    margin: 0 auto 48px 0;
  }
}

.program-item {
  &-title {
    @apply text-deepSea;
  }
  &-location {
    @apply text-deepSea;
  }
  &:hover {
    .program-item-title {
      @apply text-calmRed;
    }
    .program-item-location {
      @apply text-calmRed;
    }
  }
}

.text-animation-wrapper {
  @include for-size(tablet-portrait-up) {
    height: 119px;
  }
  @include for-size(tablet-landscape-up) {
    width: 149px;
    height:100vh;
  }
  @include for-size(phone-only) {
    height: 70px;
    width:100vw;
  }
}

#rssBlock{
  left:0px;
  position: absolute;
  height: 149px;
  width:100vh;
  overflow: hidden;
  transform-origin: bottom left;
  bottom: 0;
  @include for-size(tablet-portrait-up) {
    transform: rotate(0deg);
    height: 119px;
    width:100vw;
    margin-left: 0;
  }
  @include for-size(tablet-landscape-up) {
    transform: rotate(270deg);
    height: 149px;
    width:100vh;
    margin-left: 147px;
  }
  @include for-size(phone-only) {
    transform: rotate(0deg);
    height: 70px;
    width:100vw;
    margin: 0;
  }
}

/*remove p*/
.cnnContents {
  width:100%;
  padding-top: 20px;
  margin:0 auto;
  white-space: nowrap;
  text-transform: uppercase;
  @include for-size(phone-only) {
    padding-top: 14px;
  }
}

.marqueeStyle {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 20s linear infinite;
  animation: scrolling-left1 20s linear infinite;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  @include for-size(tablet-portrait-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 87px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  @include for-size(tablet-landscape-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 120px;
    line-height: 117px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  @include for-size(phone-only) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 46px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
}

.marqueeStyle2 {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 20s linear infinite;
  animation: scrolling-left2 20s linear infinite;
  animation-delay: 10s;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  @include for-size(tablet-portrait-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 87px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  @include for-size(tablet-landscape-up) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 120px;
    line-height: 117px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  @include for-size(phone-only) {
    font-family: 'Thunder';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 46px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
}

.color-white {
  -webkit-text-stroke-color: white;
}

.color-dark {
  -webkit-text-stroke-color: #15173E;
}

/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
  0% {transform: translateX(100%);
    -webkit-transform: translateX(100%);}
  100% {transform: translateX(-100%);
    -webkit-transform: translateX(-100%);}
}
@keyframes scrolling-left2 {
  0% {transform: translateX(0%);
    -webkit-transform: translateX(0%);}
  100% {transform: translateX(-200%);
    -webkit-transform: translateX(-200%);}
}

@-webkit-keyframes scrolling-left1 {
  0% {-webkit-transform: translateX(100%);}
  100% {-webkit-transform: translateX(-100%);}
}
@-webkit-keyframes scrolling-left2 {
  0% {-webkit-transform: translateX(0%);}
  100% {-webkit-transform: translateX(-200%);}
}

.flex-wrapper {

}

.profile-image {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  @include for-size(tablet-portrait-up) {
    width: 165px;
    height: 165px;
  }
  @include for-size(tablet-landscape-up) {
    width: 204px;
    height: 204px;
  }
  @include for-size(phone-only) {
    width: 204px;
    height: 204px;
  }
}