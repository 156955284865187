@import "../../assets/style/mixins";

@function set-color($color) {
    @if (lightness($color) > 40) {
      @return #000;
    }
    @else {
      @return #FFF;
    }
}

.header {
    width: 100vw;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    @include for-size(phone-only) {
        width: 100vw;
        height: 48px;
        padding: 0;
        align-items: center;
        flex-direction: row;
        padding: 0 24px;
        @apply border-b border-deepSea;
        border-right: none;
        z-index: 30;
    }
    @include for-size(tablet-landscape-up) {
        z-index: 21 !important;
    }
}

nav {
    overflow: auto;
}

.menu {
    width: 100vw;
    height: 100vh;
    z-index: 20;
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    padding: 64px 120px;
    flex-direction: column;
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
    -webkit-transition: background-color .5s linear;
    -ms-transition: background-color .5s linear;
    transition: background-color .5s linear;
    &__item {
        display: inline-block;
        margin-bottom: 32px;
        transition: all .5ms ease-in-out;
        z-index: 10;
        a {
            &:hover {
                cursor: pointer;
                font-style: italic;
                text-decoration: underline;
            }
        }
        @include for-size(tablet-portrait-up) {
            margin-bottom: 48px;
        }
    }
    &__close {
        position: fixed;
        right: 48px;
        top: 48px;
        z-index: 11;
        display: inline-flex;
        @include for-size(phone-only) {
            right: 12px;
            top: 12px;
        }
    }
    @include for-size(tablet-portrait-up) {
        font-size: 48px;
        line-height: 48px;
    }
    @include for-size(desktop-up) {
        font-size: 80px;
        line-height: 99px;
    }
    @include for-size(phone-only) {
        font-size: 32px;
        line-height:39.68px;
        padding: 84px 24px 24px;
        &__item {
            margin-bottom: 24px;
        }
    }
}

.slide-in-left--menu {
    -webkit-animation: slide-in-left--menu 1s alternate both;
    animation: slide-in-left--menu 1s alternate both;
    display: flex;
}

@-webkit-keyframes slide-in-left--menu {
    0% {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slide-in-left--menu {
    0% {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.slide-in-top {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    @include for-size(phone-only) {
        animation: none;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-in-top {
    0% {
        display: flex;
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-top {
    0% {
        display: flex;
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in-right {
    display: flex;
    -webkit-transform: translateX(-100vw);
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
    }
}

.image {
    padding: 10vh 0;
    background: url("../../assets/images/sponzori-desktop.png");
    width: 2038px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}