@import "../../assets/style/mixins";

.single-post {
  @include for-size(tablet-portrait-up) {
    margin: 140px 0 80px;
  }
  @include for-size(tablet-landscape-up) {
    margin: 120px 0 120px 0;
  }
  @include for-size(phone-only) {
    margin: 40px 0 64px;
  }
  &__title {
    @include for-size(tablet-landscape-up) {
      font-size: 2.5rem;
      line-height: 3rem;
      margin-top: 0;
      width: 100%;
    }
    @include for-size(desktop-up) {
      font-size: 4.25rem;
      line-height: 5.25rem;
      margin-top: 120px;
      width: 80%;
    }
    @include for-size(phone-only) {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 0;
      width: 100%;
    }
  }
  iframe {
    width: 100%;
    margin-bottom: 16px;
  }
  img {
    width: 100%;
    &:not(.featured) {
      margin-bottom: 16px;
    }
  }
  p {
    font-family: 'Inter', serif;
    font-size: 1.125rem;
    margin-bottom: 32px;
    @include for-size(phone-only) {
      font-size: 1rem;
      margin-bottom:16px;
    }
  }
  a {
    @apply text-deepSea underline;
    font-family: 'Inter', serif;
  }
}
